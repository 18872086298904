import './dapps-landing.scss';

import { ImportToolsAppButton, FiatOnboardingAppButton, EthOnboardingAppButton, AirdropAppButton } from '/components/dapps.js';
import { FiatLoginLink, WalletLinks } from '/components/dapps.js';

import { PriceTicker } from '/components/price-ticker/price-ticker.js';

export function DappsLanding(props) {
    return (
        <div className="dapps-landing">
            <h1>New to Alltra?</h1>
            <h2>Follow these steps</h2>
            <DappsLandingPanel msg="Add the Alltra Chain to your crypto wallet">
                <ImportToolsAppButton />
            </DappsLandingPanel>
            <DappsLandingArrow />
            <DappsLandingPanel msg="Purchase 11::11">
                <FiatOnboardingAppButton />
                <div><b>OR</b></div>
                <EthOnboardingAppButton />
            </DappsLandingPanel>
            <DappsLandingArrow />
            <DappsLandingPanel msg="Redeem your HYBX Airdrop">
                <AirdropAppButton />
            </DappsLandingPanel>
        </div>
    )
}

export function DappsSigninLanding(props) {
    const { quote } = props;

    const { XAU_USD, ETH_USD, ETH_AUD } = quote;
    const AUD = Math.round(XAU_USD / ETH_USD * ETH_AUD * 100) / 100;

    return (
        <div className="dapps-signin-landing">
            <img src="/assets/alltra-logo-trimmed.png"/>
            <h1>Welcome to the Alltra APP</h1>
            <p><b>1oz</b> of <b>GOLD</b> is <b>${AUD} AUD</b><br/><br/><b>1oz</b> of <b>GOLD</b> is <b>1000 11::11</b></p>
            <DappsLandingPanel msg="Login / Signup to use the app and buy 11::11">
                <FiatLoginLink></FiatLoginLink>
                <br/><br/><br/><br/>
                <p>Don't have a wallet yet? Get started by setting up your wallet with</p>
                <WalletLinks/>
            </DappsLandingPanel>
        </div>
    )
}


export function DappsLandingPanel(props) {
    const { msg, children } = props;
    return (
        <div className="dapps-landing-panel">
            <h3>{msg}</h3>
            <div className="apps">
                <div className="flex-1"></div>
                {children}
                <div className="flex-1"></div>
            </div>
        </div>
    )
}


export function DappsLandingArrow() {
    return (
        <div className="dapps-landing-arrow">

        </div>
    )
}
