


import { createRoot } from 'react-dom/client';
import { DappsTemplate } from '/components/dapps.js';

import { FirstVisitRedirect } from '/components/dapps.js';
import { DappsLanding, DappsSigninLanding, DappsLandingPanel, DappsLandingArrow } from './dapps-landing.js';
import { WalletLinks } from '/components/dapps.js';

import { overlay } from '/components/overlay.js';
import { notifier } from '/components/notifier.js';
import { quoter } from '/apps/common/quoter.js';

const root = createRoot(document.getElementById('root'));




const state = window.state = {
    overlay: {
        className: 'hide',
        content: null,
        hide: hide,
    },

    notifier: {
        input: '',
    }
}

//TODO think of a mechanism for checking whether the chain is imported without using a localstorage variable
// kill with localStorage.removeItem('first-visit-redirect')
if(/^localhost/.test(location.hostname) || /^\/devtest/.test(location.pathname) || !localStorage.getItem('first-visit-redirect')) {
    localStorage.setItem('first-visit-redirect', true);
    overlay.show(<FirstVisitRedirect />)
}

quoter.currentQuotePromise.then(q => {
    // console.log('rendering', q)
    render({ quote: q });
})

// return render({ quote });

function render() {
    root.render(
        <> 
            <DappsTemplate {...{overlay}}>
                {/* <div>
                <input id="overlay-input" onChange={overlayInputChanged} type="text" placeholder="overlay text..."></input>
                    <button id="overlay-button" onClick={show}>trigger overlay</button>
                </div>
                <div>
                    <input id="notification-textarea" onChange={notificationInputChanged} placeholder="notification text..."></input>
                    <button id="notification-button" onClick={sendNotification}>send notification</button>
                </div> */}
                <DappsSigninLanding quote={quote} />
            </DappsTemplate>
        </>
    );

}





function overlayInputChanged(e) {
    // console.log('overlayInputChanged', this, e);
    state.overlayInput = e.target.value;
}

function show() {
    console.log('show', state.overlayInput);
    overlay.show(state.overlayInput);
}

function hide() {
    overlay.hide();
}

function notificationInputChanged(e) {
    state.notifierInput = e.target.value;
}

function sendNotification() {
    notifier.msg(state.notifierInput);
}
